@use "../global/index" as global;

.customTypingIndictor__container {
  padding: 3px 10px;
  border-radius: 20px;
  display: inline-block;
}

.customTypingIndictor__dot {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
  background-color: global.$accent-2;
  animation: 1.2s typing-dot ease-in-out infinite;
}

.customTypingIndictor__dot:nth-of-type(2) {
  animation-delay: 0.15s;
}

.customTypingIndictor__dot:nth-of-type(3) {
  animation-delay: 0.25s;
}

@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}