@use "../global" as global;


.message {

  &__row-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__row-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__left-message {
    position: relative;
    margin-left: global.$base * 2;
    margin-bottom: global.$base * 2;
    padding: global.$base;
    background-color: global.$gray-20;
    max-width: 90%;
    align-self: flex-start;
    text-align: left;
    border: 1px solid global.$gray-55;
    border-radius: global.$base * 2 global.$base * 2 global.$base * 2 0;
    color: white;

    table {
      border-collapse: collapse;
      margin-top: global.$base;
      margin-bottom: global.$base;
      border-radius: global.$base * 2;
    }

    th,
    td {
      border: 1px solid global.$gray-55;
      padding: global.$base;
    }

    th {
      background-color: #494949;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr {
      color: white;
    }

    h1 {
      font-size: 1.8rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    h3 {
      font-size: 1.4rem;
    }

    h4 {
      font-size: 1.2rem;
    }

    h5 {
      font-size: 1rem;
    }

    h6 {
      font-size: 1rem;
    }
  }

  &__right-message {
    position: relative;
    margin-right: global.$base * 2;
    margin-bottom: global.$base * 2;
    padding: global.$base;
    background-color: var(--accent-3);
    max-width: 90%;
    align-self: flex-end;
    text-align: left;
    border: 1px solid global.$gray-55;
    border-radius: global.$base * 2 global.$base * 2 0 global.$base * 2;
    color: var(--white);
  }

  &__timestamp {
    position: absolute;
    font-size: global.$font-size-sm;
    margin-top: 10px;
    bottom: -3px;
    right: 5px;
  }

  &__left-display-name {
    padding-left: global.$base * 2;
    font-size: global.$xs;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__right-display-name {
    padding-right: global.$base * 2;
    text-align: end;
    font-size: global.$xs;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__file {
    background: rgba(0, 0, 0, 0.3);
    padding: global.$base;
    margin: global.$base;
    border-radius: global.$border-radius;

    button {
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(global.$base / 2);
    }
  }

  &__out-context {
    background-color: global.$gray-32;
  }

  &__left-controls {
    // position: absolute;
    right: 0;
  }

  &__right-controls {
    // position: absolute;
    left: 0;
  }
}