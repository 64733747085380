@use "../global" as global;

.chat {
  position: relative;
  max-width: global.$lg-break;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  &__fixed-top {
    top: 4rem; //based on top header plus some
    z-index: 10;
    width: global.$lg-break;
    position: relative;

    @media (max-width: global.$lg-break) {
      width: 100vw;
    }

    @media (max-width: 1300px) and (min-width: global.$lg-break) {
      width: calc(100vw - 15rem);
    }

    @media (max-width: global.$sm-break) {
      top: 6rem;
    }
  }

  &__section-header {
    background-color: var(--background);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 global.$base;
    align-items: center;
    position: fixed;
    top: 3rem; //based on top header
    z-index: 2;
    max-width: global.$lg-break;
    padding-bottom: global.$base;
    border-bottom: solid var(--accent-3) 2px;

    @media (min-width: global.$lg-break) and (max-width: 1300px) {
      max-width: calc(100vw - 15rem);
    }

    @media (max-width: global.$sm-break) {
      flex-direction: column;
    }

    &__title {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;

      @media (max-width: global.$lg-break) {
        justify-content: space-between;
      }
    }
  }

  &__chat-log {
    width: 100%;
    margin-top: 7rem; //based on top bar height + section header height
    margin-bottom: 6rem; //based on input-form height

    @media (max-width: global.$lg-break) {
      margin-top: 12rem;
    }

    &__in-context {
      width: 100%;
      height: 1rem;
      border-bottom: 1px solid var(--black);
      text-align: center;
      margin-bottom: 0.6rem;

      span {
        font-size: 1rem;
        background-color: var(--background);
        padding: 0 10px;
      }
    }
  }

  &__wizard {
    background-color: var(--white);
    width: 100%;
    border-radius: global.$border-radius;
    padding: 1rem;
  }

  &__prompt-list {
    width: 100%;
    display: grid;
    grid-gap: global.$base;
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
    overflow-y: auto;
    max-height: 24rem;
  }

  &__input-form {
    position: fixed;
    bottom: 0;
    background-color: var(--white);
    z-index: 10;
    width: global.$lg-break;
    padding: 0.4rem 0.2rem;
    border-radius: global.$border-radius;

    @media (max-width: global.$lg-break) {
      width: 100vw;
    }

    @media (max-width: 1300px) and (min-width: global.$lg-break) {
      width: calc(100vw - 14rem);
    }
  }
}