@use "../global" as global;

.courses {

  max-width: global.$lg-break;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: global.$base;
  width: 100%;

  &__list {
    width: 100%;
    display: grid;
    grid-gap: global.$base;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }

  &__section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: global.$sm-break) {
      flex-direction: column;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
    }
  }

  &__add {
    width: 100%;
    background-color: var(--white);
    padding: 1rem;
    border-radius: 0.5rem;
  }

  &__discard_background {
    &:hover {
      color: var(--white) !important;
      background-color: var(--error) !important;
    }

    color: var(--error) !important;
  }

  &__delete_background {
    &:hover {
      color: var(--error) !important;

    }
  }

}