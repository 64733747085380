@use "./variables";

html,
body {
  background-color: var(--background);
  color: var(--primary);
}

button {
  cursor: pointer;
}

// ------- Fonts ------- //
$montserrat: "Montserrat";
$opensans: "OpenSans";

// ------- Font Sizes ------- //
$font-size-body: 1rem !default;

$font-size-h1: 3.5rem !default;
$font-size-h2: 3rem !default;
$font-size-h3: 2.4rem !default;
$font-size-h4: 2rem !default;
$font-size-h5: 1.8rem !default;
$font-size-h6: 1.6rem !default;

// ------- Element Variables ------- //
// Headers
$default-header-font-family: $montserrat;
$default-header-font-weight: 700;
$default-header-margin: 0;
$default-header-color: var(--black);
$heading-font-sizes: (
  1: $font-size-h1,
  2: $font-size-h2,
  3: $font-size-h3,
  4: $font-size-h4,
  5: $font-size-h5,
  6: $font-size-h6,
);

// ------- Headers ------- //
h1,
h2 {
  font-family: $default-header-font-family;
  font-weight: $default-header-font-weight;
  margin: $default-header-margin;
  color: $default-header-color;
}

h3,
h4,
h5,
h6 {
  font-family: $montserrat;
  font-weight: $default-header-font-weight;
  margin: $default-header-margin;
  color: $default-header-color;
}

@each $i, $size in $heading-font-sizes {
  h#{$i} {
    font-size: $size;
  }
}

h6 {
  font-weight: 600;
}

// ------- Base ------- //
$base: 0.4rem !default;

// ------- General Sizing ------- //
$xs: $base * 2.5 !default;
$sm: $base * 3 !default;
$md: $base * 4 !default;
$lg: $base * 6 !default;
$xl: $base * 8 !default;

// ------- Border Radius & Width ------- //
$border-radius: 0.5rem;
$border-width: 0.125rem;

// ------- Breakpoints ------- //
$sm-break: 425px;
$md-break: 768px;
$lg-break: 1024px;
$xl-break: 1440px;

// ------- Font Sizes ------- //
$font-size-xs: 14px;
$font-size-sm: 16px;
$font-size-md: 18px;
$font-size-lg: 20px;
$font-size-xl: 24px;

$default-font-weight: 500;

//p
p {
  font-size: $font-size-sm;
  margin: $base 0;
  font-family: $opensans;
}

div {
  font-size: $font-size-sm;
  margin: 0;
  font-family: $opensans;
}

span {
  font-size: $font-size-sm;
  font-family: $opensans;
}

label {
  font-size: $font-size-sm;
  font-family: $opensans;
}

// ------- button ------- //
button {
  background: none;
  border: none;
  padding: 0;
  font-family: $opensans;
  color: var(--black);

  &:focus {
    outline: auto;
  }
}

// ------- a ------- //
a {
  color: var(--primary);
  text-decoration: underline;
  font-family: $opensans;

  &:hover {
    color: var(--accent-3);
  }
}

.error {
  color: var(--error);
}

* {
  user-select: text;
}

hr {
  background-color: var(--accent-3);
  height: 1px;
  opacity: 1;
  border: 0;
  border-top: 1px solid;
  color: var(--accent-3);
  width: 100%;
}

.for-screen-readers-only {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

//for dynamic form
.d-form {
  #d-form__form>* {
    margin: $base * 4 0;
  }
}

.privateRoute {
  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: 13rem;
  margin-top: 3rem;
  width: 100%;

  @media (max-width: $lg-break) {
    margin-top: 3rem;
    margin-left: 0;
  }
}

.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 1; // amount of line you want
  -webkit-box-orient: vertical;
  overflow: hidden;
}