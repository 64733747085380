@use "../global" as global;

.library {

  max-width: global.$lg-break;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: global.$base;
  width: 100%;

  &__folder-list {
    width: 100%;
    display: grid;
    grid-gap: global.$base;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }

  &__prompt-list {
    width: 100%;
    display: grid;
    grid-gap: global.$base;
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  }

  &__section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: global.$sm-break) {
      flex-direction: column;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
    }
  }

  &__filter {
    display: flex;
    flex-direction: column;
    padding: global.$base;

    &__title {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }

  &__searchbar {
    width: 100%;
    margin: global.$base;
  }

}